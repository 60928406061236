import React, { useRef, useEffect } from 'react'
import { renderToString } from 'react-dom/server'

export function Comment({ children }) {
	return <div dangerouslySetInnerHTML={{ __html: `<!-- ${renderToString(children)} -->` }} />
}

export function RenderAfterMount({ children }) {
  const c = useRef([])

  useEffect(() => {
    c.current = children
  }, [children])

  return c.current
}

export function generateMeta({ title, defaultTitle, titleTemplate, description, domain, url, images, robots }) {
  const imageTags = generateImageMeta(images || [], domain)
  return [
    { name: 'robots', content: robots },
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    ...imageTags.schema,
    { name: 'description', content: description },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    ...imageTags.twitter,
    { name: 'og:title', content: title },
    { name: 'og:url', content: url },
    ...imageTags.openGraph,
    { name: 'og:description', content: description }
  ].filter(tag => tag.content)
}

export function generateImageMeta(images, domain) {
  return {
    schema: images.length === 0 ? [] : images.map(image => [
      { itemprop: 'image', content: image.src }
    ]).reduce((a, b) => a.concat(b), []),
    twitter: images.length === 0 ? [] : ((image) => [
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:image', content: `http://${domain}${image.src}` },
      { name: 'twitter:image:alt', content: image.alt }
    ])(images[1]),
    openGraph: images.length === 0 ? [] : images.map(image => [
      { name: 'og:image', content: image.src.replace(`https://`, `http://`) },
      { name: 'og:image:secure_url', content: image.src },
      { name: 'og:image:width', content: image.width },
      { name: 'og:image:height', content: image.height },
      { name: 'og:image:alt', content: image.alt },
    ]).reduce((a, b) => a.concat(b), [])
  }
}

export function setVendorStyle(element, property, value) {
	const uppercase = property.charAt(0).toUpperCase() + property.substr(1);
	element.style["webkit" + uppercase] = value;
	element.style["moz" + uppercase] = value;
	element.style["ms" + uppercase] = value;
	element.style["o" + uppercase] = value;
	element.style[property] = value;
}
