import React, { Fragment } from 'react'
import { useEventListener } from '../hooks/hooks'
import { generateMeta } from '../utilities/utilities'
import { graphql, StaticQuery, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { throttle } from 'throttle-debounce'
import './info.css'
import './inline-slides.css'

export default ({ toggleInfo, slides, slider, setSlider, pageData, location }) => {
  useEventListener('scroll', throttle(50, (e) => {
    if(document.documentElement.clientWidth <= 1000) return
    const scrollTop = e.target.scrollTop,
         info = document.querySelector('.info')
    if(scrollTop > 45) info.classList.add('scroll')
    else info.classList.remove('scroll')
  }), { passive: true, element: typeof document !== 'undefined' && document.querySelector('.info') })

  return (
    <StaticQuery
      query={infoQuery}
      render={({ prismicSettings: { data }, desktop, mobile, allSitePage: { edges } }) => {
        const desktopBuildTimeSplit = desktop.buildTime.split(' '),
              desktopBuildYear = desktopBuildTimeSplit[desktopBuildTimeSplit.length - 1],
              creditCategories = data.body.filter(slice => slice.slice_type === 'credit_category'),
              collaborators = creditCategories.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue.items), []
              ),
              meta = [
                { name: 'charSet', content: 'utf-8' },
                { name: 'twitter:site', content: '@zoebruns' },
                { name: 'og:site_name', content: data.title.text },
                ...generateMeta({
                  title: data.title.text,
                  description: data.biography.text,
                  domain: `zoebruns.com`
                })
              ]

        return (
          <div className="info">
            <Helmet
              htmlAttributes={{ lang: `en` }}
              defaultTitle={data.title.text}
              titleTemplate={`%s – ${data.title.text}`}
              meta={meta}
              defer={false} />
            <div className="banner">
              <div>{data.name.text} ©{desktopBuildYear}</div>
              <div>Last update: {desktop.buildTime}</div>
              <div><a href={`tel:${data.phone.text.replace(/\D/g,'')}`}>{data.phone.text}</a></div>
              <div><a href={`mailto:${data.email.text}`}>{data.email.text}</a></div>
              <div><a href="https://studio.sl">Website by Spevack Loeb {`\u2197`}</a></div>
            </div>
            <div className="mobile-banner">
              <div>{data.name.text}</div>
              <div>Copyright {desktopBuildYear}</div>
              <div><a href={`tel:${data.phone.text.replace(/\D/g,'')}`}>{data.phone.text}</a></div>
              <div>Last update: {mobile.buildTime}</div>
              <div><a href={`mailto:${data.email.text}`}>{data.email.text}</a></div>
              <div><a href="https://studio.sl">Site by Spevack Loeb</a></div>
            </div>
            <Link
              className="close"
              to={location.pathname}
              onClick={(e) => {
                e.preventDefault()
                toggleInfo()
              }}>
              <span>Close</span>
            </Link>
            <div className="main">
              <div className="biography" dangerouslySetInnerHTML={{ __html: `<span class="category-title">Biography</span>` + data.biography.html }} />
              <p className="clients">
                <span className="category-title">Selected Clients</span>
                {data.clients.map((c, i, a) => c.client
                  ? (
                    <span key={c.client.document[0].uid}>
                      {edges.find(n => n.node.context.uid === c.client.document[0].uid)
                        ? <Link
                            to={location.pathname.indexOf(`/${c.client.document[0].uid}`) > -1 ? '/' : `/${c.client.document[0].uid}`}
                            className={`client-link ${location.pathname.indexOf(`/${c.client.document[0].uid}`) > -1 ? 'active' : ''}`}
                          >{c.client.document[0].data.name.text}</Link>
                        : <span className="client-nolink">{c.client.document[0].data.name.text}</span>
                      }
                      {i === a.length - 1
                        ? c.client.document[0].data.name.text.substr(-1) === '.'
                          ? '' : '.'
                        : ', '}
                    </span>
                  ) : null
                )}
              </p>
              <div className="inline-slides">{slides && slides.map(object => object.nav)}</div>
              <div className="credits">
                <span className="category-title">Collaborators</span>
                <div className="preamble" dangerouslySetInnerHTML={{ __html: data.description.html }} />
                <p className="category">
                  {(creditCategories[0].primary.sort === 'Alphabetically'
                    ? collaborators.sort(
                      (a, b) => {
                        const aReversed = a.collaborator.text.split(' ').reverse().join(' ').toLowerCase(),
                              bReversed = b.collaborator.text.split(' ').reverse().join(' ').toLowerCase()
                        return aReversed > bReversed
                          ? 1 : -1
                      }
                    ) : collaborators).map(
                      (c, i, a) => c.collaborator
                        ? (
                          <Fragment key={c.collaborator.text}>
                            {a.length > 1 && i === a.length - 1
                              ? 'and '
                              : null}
                            <span>{c.collaborator.text}</span>
                            {a.length > 1 && i !== a.length - 1
                              ? ', '
                              : c.collaborator.text.substr(-1) === '.'
                                ? '' : '.'}
                          </Fragment>
                        ) : null
                  )}
                </p>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

const infoQuery = graphql`
  query {
    desktop: site {
      buildTime(formatString: "dddd MMMM Do, YYYY")
    }
    mobile: site {
      buildTime(formatString: "M/DD/YY")
    }
    prismicSettings {
      id
      uid
      type
      data {
        title {
          text
        }
        name {
          text
        }
        phone {
          text
        }
        email {
          text
        }
        slideshow {
          id
        }
        biography {
          html
          text
        }
        clients {
          client {
            document {
              uid
              data {
                name {
                  text
                }
              }
            }
          }
        }
        description {
          html
          text
        }
        body {
          id
          items {
            collaborator {
              text
            }
          }
          slice_type
          primary {
            title1 {
              html
              text
            }
            sort
          }
        }
      }
    }
    allSitePage(
      filter: {
        context: {
          type: {
            eq: "GeneratedSlideshow"
          }
        }
      }
    ) {
      edges {
        node {
          path
          context {
            uid
          }
        }
      }
    }
  }
`
