import React, { useState, useEffect, useRef } from 'react'
import { useEventListener } from '../hooks/hooks'
import { generateMeta } from '../utilities/utilities'
import { generateSliceObject } from '../templates/slideshow.jsx'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Info from './info'
import 'focus-visible'
import 'reset-css'
import fonts from '../assets/fonts'
import Fonts from './fontface'
import './fontface.css'
import './layout.css'
import './queries.css'

function generateSlideshow({ data, location }, passwordInput, setPasswordInput, slider, getIndex) {
  if(!data) return {}

  const uid = location.pathname === '/' ? '/' : `/${(data.prismicSlideshow || data.generatedSlideshow).uid}`,
        url = `https://zoebruns.com${uid === '/' ? '' : uid}`,
        slideshow = (data.prismicSlideshow || data.generatedSlideshow).data,
        type = data.prismicSlideshow !== null ? 'page' : 'client',
        password = slideshow.password !== null ? slideshow.password : undefined,
        hasPassword = typeof password !== 'undefined',
        hasCredentials = !hasPassword || passwordInput === password,
        title = !hasCredentials
          ? `Password Required`
          : location.pathname !== '/'
            ? slideshow.title.text
            : undefined,
        description = type !== 'client' || hasPassword
          ?  undefined
          : `Work for ${slideshow.title.text}`,
        // Don't index non-client or passworded slideshows
        robots = (location.pathname !== '/' && type !== 'client') || hasPassword
          ? `noindex`
          : undefined

  let slideCount = 0

  const generatedSlides = !hasCredentials ? [] : slideshow.body.map(
    (slice, i, a) => {
      const object = generateSliceObject(getIndex, slideCount, slice, slider, uid)
      if(object.slide) slideCount++
      return object
    }
  ).filter(object => object.slide)

  const firstThreeImages = generatedSlides.filter(
    slide => slide.slice.primary && slide.slice.primary.image
  ).slice(0,3).map(slide => {
    return {
      src: `${slide.slice.primary.image.url}&w=1024`,
      width: slide.slice.primary.image.dimensions.width,
      height: slide.slice.primary.image.dimensions.height,
      alt: slide.slice.primary.caption.text || slide.slice.primary.image.alt
    }
  })

  const generatedMeta = generateMeta({
    title: title,
    description: description,
    domain: `zoebruns.com`,
    url: url,
    images: firstThreeImages,
    robots: robots
  })

  const object = {
    meta: generatedMeta,
    slides: generatedSlides,
    length: slideCount,
    uid: uid,
    url: url,
    slideshow: slideshow,
    type: type,
    password: password,
    hasPassword: hasPassword,
    passwordInput: passwordInput,
    setPasswordInput: setPasswordInput,
    hasCredentials: hasCredentials,
    title: title
  }

  return object
}

// Do the layout
export default function Layout(props) {
  // Knows if info is open or closed
  const [info, setInfo] = useState(false),
        toggleInfo = () => setInfo(!info)

  useEffect(() => {
    const e = new CustomEvent('info', { detail: info })
    window.dispatchEvent(e)
  }, [info])

  // Keeps index
  const [index, setIndex] = useState(0),
        indexRef = useRef(index),
        getIndex = () => indexRef.current

  useEffect(() => {
    indexRef.current = index
  }, [index])

  // Keeps slider
  const [slider, setSlider] = useState({ current: null })
  // Keeps user password input
  const [passwordInput, setPasswordInput] = useState(undefined)
  // Keeps slideshow
  const [slideshow, setSlideshow] = useState(() => generateSlideshow(props, passwordInput, setPasswordInput, slider, getIndex))

  // When slideshow changes
  // * recalculate slides
  // * adjust length
  // * reset index
  // * reset password input
  useEffect(() => {
    const generatedSlideshow = generateSlideshow(props, passwordInput, setPasswordInput, slider, getIndex)
    setSlideshow(generatedSlideshow)
    setIndex(0)
    if(!generatedSlideshow.hasPassword) setPasswordInput(undefined)
  }, [props.data, slider, passwordInput])

  // Observes clicks to see if info should be closed
  useEventListener('click', (e) => {
    // We only want to close info when it is open
    if(!info) return
    const a = e.target.closest('a'),
          ignore = a
            // If target has a parent anchor
            // test if it matches an ignored class
            ? a.classList.contains('info-link') ||
              a.classList.contains('client-link') ||
              a.classList.contains('inline-slide')
            // If target does not have a parent anchor
            // test if it has .info as a parent
            : e.target.closest('.info')
    // Ignore the event if either of the above are true
    if(ignore) return
    // Timeout prevents classList from changing before slideshow's clickHandler fires
    setTimeout(() => setInfo(false), 0)
  }, { passive: true })

  // Observes scroll to see if desktop info banner should be given a background
  useEventListener('scroll', (e) => {
    if(document.documentElement.clientWidth <= 1000) return
    const scrollTop = e.target.scrollTop,
          infoElement = document.querySelector('.info')
    if(scrollTop > 45) infoElement.classList.add('scroll')
    else infoElement.classList.remove('scroll')
  }, { passive: true })

  return (
    <div id="root" className={info ? 'info-open' : 'info-closed'}>
      <Fonts
        display="swap"
        fonts={fonts} />
      <div className="container">
        <Info
          toggleInfo={toggleInfo}
          index={index}
          slider={slider}
          slides={slideshow.slides}
          pageData={props.data}
          location={props.location}
        />
        <Helmet
          title={slideshow.title}
          meta={slideshow.meta}
          defer={false} />
        {React.cloneElement(props.children, {
          info, setInfo, toggleInfo,
          index, setIndex, getIndex,
          slider, setSlider, slideshow
        })}
        <InfoLink
          toggleInfo={toggleInfo}
          location={props.location}
        />
      </div>
    </div>
  )
}

export const InfoLink = ({ toggleInfo, location }) => {
  return (
    <Link to={location.pathname} className="info-link" onClick={(e) => {
      e.preventDefault()
      if(document.documentElement.clientWidth <= 1000) window && window.scroll({
        top: document.querySelector('.info').offsetTop,
        behavior: 'smooth'
      })
      else toggleInfo()
    }}>
      <span className="desktop">Information</span>
      <span className="mobile">Info</span>
    </Link>
  )
}
