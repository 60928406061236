import React from 'react'

const fontFace = (fonts, family, weight, style, formats, display) => (
  `@font-face {` +
  `font-family: "${family.replace(/([A-Z])/g, ' $1').substr(1)}";` +
  fontSrc(fonts, family, weight, style, formats) +
  `font-weight: ${weight};font-style: ${style};font-display: ${display || 'auto'};}`
)

const fontSrc = (fonts, family, weight, style, formats) => {
  const order = ['eot', 'woff2', 'woff', 'ttf']
  return formats.slice().filter(format =>
    order.indexOf(format) > -1
  ).sort((a, b) =>
    order.indexOf(a) - order.indexOf(b)
  ).map((format, i, a) => {
    if(!format) return ``
    switch(format) {
      case 'eot':
        return `src: url("${fonts[family][weight][style].eot}");` +
               `src: url("${fonts[family][weight][style].eot}?#iefix") format("embedded-opentype")${i < a.length - 1 ? ',' : ';'}`
      default:
        return `${i === 0 ? 'src: ' : ''}` +
               `url("${fonts[family][weight][style][format]}") format("${format === 'ttf' ? 'truetype' : format}")` +
               `${i < a.length - 1 ? ',' : ';'}`
    }
  }).join(``)
}

export default ({ fonts, display }) => {
  if(fonts === null || typeof fonts !== "object") return null
  return (
    <style type="text/css">
      {`${Object.keys(fonts).map(family => (
        Object.keys(fonts[family]).map(weight => (
          Object.keys(fonts[family][weight]).map(style => (
            fontFace(fonts, family, weight, style, Object.keys(fonts[family][weight][style]), display)
          )).join(`\n`)
        )).join(`\n`)
      )).join(`\n`)}`}
    </style>
  )
}
