import React, { useState, useEffect, useRef } from 'react'
import { useEventListener, usePlayer, usePlayPauseCondition, usePrevious } from '../hooks/hooks'
import { RenderAfterMount, setVendorStyle } from '../utilities/utilities'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import { throttle } from 'throttle-debounce'
import lazysizes from 'lazysizes'
import './slick.min.css'
import './slideshow.css'
import './slideshow.password.css'

export function generateSliceObject(getIndex, index, slice, slider, uid) {
  let object = {
    index: index,
    getIndex: getIndex,
    slice: slice,
    slide: null,
    slider: slider,
    nav: null,
    uid: uid
  }

  if(!slice.primary) return object

  const primary = slice.primary

  let anchor_x,
      anchor_y

  switch(slice.slice_type) {
    case 'image':
      if(!primary.image.url) break
      if(!primary.image.localFile) break

      // Generate slide
      const ratio = primary.image.dimensions.width / primary.image.dimensions.height

      anchor_x = primary.anchor_x === Number(primary.anchor_x)
        ? primary.anchor_x
        : 50
      anchor_y = primary.anchor_y === Number(primary.anchor_y)
        ? primary.anchor_y
        : 50

      object.slide = (
        <Slide key={slice.id} object={object}>
          <div>
            <div style={{
              paddingBottom: `${1 / ratio * 100}%`
            }}>
              <img
                className="image lazyload"
                src={`${primary.image.url}&w=1024`}
                srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-srcset={`${primary.image.url}&w=415&q=60 415w, ${primary.image.url}&w=830&q=60 830w, ${primary.image.url}&w=1024&q=60 1024w, ${primary.image.url}&w=2048&q=60 2048w`}
                data-sizes={primary.scale === 'Cover'
                  ? `(max-width: 1000px) 100vw, auto`
                  : `(max-width: 1000px) 100vw, calc(${ratio} * (100vh - 120px))`
                }
                style={primary.scale === 'Cover' ? {
                  width: `100%`,
                  height: `100%`,
                  objectPosition: `${anchor_x}% ${anchor_y}%`,
                  objectFit: `cover`
                } : {
                  top: `60px`,
                  height: `calc(100vh - 120px)`,
                  width: `calc(${ratio} * (100vh - 120px))`,
                  left: `calc((100vw - (${ratio} * (100vh - 120px))) / 2)`
                }}
                alt={primary.image.alt || primary.caption && primary.caption.text || primary.client && primary.client.document[0] && primary.client.document[0].data.name.text && `Work for ${primary.client.document[0].data.name.text}`}
              />
              <img
                className="image lqip"
                src={primary.image.localFile.childImageSharp.fluid.base64}
                style={primary.scale === 'Cover' ? {
                  width: `100%`,
                  height: `100%`,
                  objectPosition: `${anchor_x}% ${anchor_y}%`,
                  objectFit: `cover`
                } : {
                  top: `60px`,
                  height: `calc(100vh - 120px)`,
                  width: `calc(${ratio} * (100vh - 120px))`,
                  left: `calc((100vw - (${ratio} * (100vh - 120px))) / 2)`
                }}
                alt={primary.image.alt || primary.caption && primary.caption.text || primary.client && primary.client.document[0] && primary.client.document[0].data.name.text && `Work for ${primary.client.document[0].data.name.text}`}
              />
            </div>
          </div>
        </Slide>
      )

      // Generate nav
      object.nav = (
        <InlineSlide key={slice.id} object={object}>
          <img
            className="image lazyload"
            src={`${primary.image.url}&w=${Math.round(ratio * 80)}`}
            srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            data-srcset={`
              ${primary.image.url}&w=${Math.round(ratio * 80)}&q=40 1x,
              ${primary.image.url}&w=${Math.round(ratio * 80)}&dpr=2&q=40 2x
            `}
            sizes={`${Math.round(ratio * 80)}px`}
            style={primary.scale === 'Cover' ? {
              objectPosition: `${primary.anchor_x ? primary.anchor_x : 50}% ${primary.anchor_y ? primary.anchor_y : 50}%`
            } : {
              objectFit: `contain`
            }}
            alt={primary.image.alt || primary.caption && primary.caption.text || primary.client && primary.client.document[0] && primary.client.document[0].data.name.text && `Work for ${primary.client.document[0].data.name.text}`}
          />
          <img
            className="image lqip"
            src={primary.image.localFile.childImageSharp.fluid.base64}
            alt={primary.image.alt || primary.caption && primary.caption.text || primary.client && primary.client.document[0] && primary.client.document[0].data.name.text && `Work for ${primary.client.document[0].data.name.text}`}
          />
        </InlineSlide>
      )
      break

    case 'text':
      object.slide = (
        <Slide key={slice.id} object={object}>
          <div className="text" dangerouslySetInnerHTML={{ __html: primary.text.html }} />
        </Slide>
      )

      object.nav = (
        <InlineSlide key={slice.id} object={object}>
          <div className="text" dangerouslySetInnerHTML={{ __html: primary.text.html }} />
        </InlineSlide>
      )
      break

    case 'video':
      const embedUrl = primary.embed.html.match(/src=(?:"|')([^"']+)(?:"|')/)[1],
            embedQuery = `&background=1&autoplay=0&dnt=1`, // Vimeo
            embedTitle = primary.embed.title,
            aspectRatio = primary.embed.width / primary.embed.height
      anchor_x = primary.anchor_x === Number(primary.anchor_x)
        ? primary.anchor_x
        : 50
      anchor_y = primary.anchor_y === Number(primary.anchor_y)
        ? primary.anchor_y
        : 50
      object.slide = (
        <Slide key={slice.id} object={object}>
          <div
            className="video"
            style={primary.scale === 'Cover' ? {
            } : {
              top: `60px`,
              height: `calc(100vh - 120px)`,
              maxHeight: `calc(${1 / aspectRatio} * 100vw)`, // Limit height on mobile
              width: `calc(${aspectRatio} * (100vh - 120px))`, // Limit width on desktop
              left: `calc((100vw - (${aspectRatio} * (100vh - 120px))) / 2)`,
            }}>
              <iframe
                id={`slide-${slice.id}`}
                title={embedTitle}
                src={embedUrl + embedQuery}
                frameBorder="0"
                allowFullScreen
                style={primary.scale === 'Cover' ? {
                  width: `${100 * aspectRatio}${aspectRatio < 1 ? 'vw' : 'vh'}`,
                  height: `${100 / aspectRatio}vw`,
                  minHeight: `100vh`,
                  minWidth: `100vw`,
                  top: `50vh`,
                  left: `50vw`,
                  transform: `translate(calc(-50% - (100% - 100vw) * ${(anchor_x - 50) / 100}), calc(-50% - (100% - 100vh) * ${(anchor_y - 50) / 100}))`
                } : {}} />
            </div>
        </Slide>
      )

      object.nav = (
        <InlineSlide key={slice.id} object={object}>
          <iframe
            id={`nav-${slice.id}`}
            title={embedTitle}
            src={embedUrl + embedQuery}
            frameBorder="0"
            allowFullScreen
            style={{
              pointerEvents: `none`,
              width: `${aspectRatio * 80}px`,
              height: `80px`
            }} />
        </InlineSlide>
      )

      break

    default:
      break
  }

  return object
}

function InlineSlide({ children, object }) {
  const slice = object.slice,
        primary = slice && slice.primary,
        scale =  primary.scale
          ? primary.scale.toLowerCase()
          : 'fit',
        ratio = primary.image
          ? primary.image.dimensions && primary.image.dimensions.width / primary.image.dimensions.height
          : primary.ratio
            ? primary.ratio
            : primary.embed && primary.embed.width / primary.embed.height

  const handleClick = (e) => {
    e.preventDefault()
    object.slider && object.slider.current && object.slider.current.goTo(object.index)
  }

  return (
    <Link to={object.uid} onClick={handleClick} className={`inline-slide inline-${slice.slice_type} ${scale}`} style={{
      height: `80px`,
      width: `${80 * (ratio || 1)}px`
    }}>
      {slice.slice_type === 'video' && primary.embed.provider_name === 'Vimeo' ? (
        <RenderAfterMount>
          <NavPlayer id={`nav-${slice.id}`}>{children}</NavPlayer>
        </RenderAfterMount>
      ) : children}
    </Link>
  )
}

function SlidePlayer({ children, id, object, scale }) {
  const player = usePlayer(id)

  // Unclear why this hack is necessary, but when omitted a horizontal
  // video set to cover has undesirable gaps at window top and bottom
  useEffect(() => {
    if(!(player && scale === 'cover')) return

    const repaintVideoPlayer = () => {
      player.element.style.minHeight = '101vh'
      setTimeout(() => player.element.style.minHeight = '100vh', 5)
    }

    player.on('play', repaintVideoPlayer)
    player.on('bufferend', repaintVideoPlayer)

    return function cleanup() {
      player.on('play', repaintVideoPlayer)
      player.off('bufferend', repaintVideoPlayer)
    }
  }, [player])

  usePlayPauseCondition(player, object.getIndex() - 2 <= object.index && object.index <= object.getIndex() + 2)

  return children
}

function NavPlayer({ children, id }) {
  const player = usePlayer(id),
        [info, setInfo] = useState(document.querySelector('#root.info-open'))

  // Make sure we know whether info is open
  useEventListener('info', (e) => {
    setInfo(e.detail)
  }, { passive: true })

  // Start playback if info is open
  usePlayPauseCondition(player, info)

  return children
}

function Slide({ children, object }) {
  const slice = object.slice,
        primary = slice && slice.primary,
        scale = primary.scale
          ? primary.scale.toLowerCase()
          : 'fit',
        anchor_x = primary.anchor_x === Number(primary.anchor_x)
          ? primary.anchor_x
          : 50,
        anchor_y = primary.anchor_y === Number(primary.anchor_y)
          ? primary.anchor_y
          : 50

  return (
    <div className={`slide ${slice.slice_type} ${scale}`} data-id={slice.id}>
      {scale === 'cover' && slice.slice_type === 'image' && (
        <style type="text/css" dangerouslySetInnerHTML={{ __html: `
          [data-id="${slice.id}"] > * { object-position: ${anchor_x}% ${anchor_y}%; }
        `}} />
      )}
      {slice.slice_type === 'video' && primary.embed.provider_name === 'Vimeo' ? (
        <RenderAfterMount>
          <SlidePlayer id={`slide-${slice.id}`} object={object} scale={scale}>{children}</SlidePlayer>
        </RenderAfterMount>
      ) : children}
    </div>
  )
}

export default function Slideshow({
  info, setInfo, toggleInfo,
  index, setIndex, getIndex,
  slider, setSlider, slideshow,
  passive, data, location
}) {
  // Keeps slider ref
  const sliderElement = useRef(null)

  // Imitates useImperativeHandle since refs are not working
  // possibly due to React Hot Loader or Gatsby templating implementation
  useEffect(() => {
    setSlider({
      current: {
        goTo: (i) => sliderElement.current && sliderElement.current.slickGoTo(i),
        next: () => sliderElement.current && sliderElement.current.slickNext(),
        prev: () => sliderElement.current && sliderElement.current.slickPrev()
      }
    })
  }, [])

  // Keeps viewport width
  const clientWidth = useRef(typeof document !== 'undefined' && document.documentElement.clientWidth)

  // Keeps mobile state
  const [mobile, setMobile] = useState(typeof document !== 'undefined' && document.documentElement.clientWidth <= 1000)
  const prevMobile = usePrevious(mobile)

  // Attaches resize event listener
  useEventListener('resize', throttle(50, (e) => {
    const newClientWidth = document.documentElement.clientWidth

    // Transitioned to desktop
    if(clientWidth.current <= 1000 && newClientWidth > 1000) setMobile(false)

    // Transitioned to mobile
    if(clientWidth.current > 1000 && newClientWidth <= 1000) setMobile(true)

    clientWidth.current = newClientWidth
  }), { passive: true })

  useEffect(() => {
    if(prevMobile === mobile || typeof prevMobile === 'undefined') return

    if(!mobile) slider.current && slider.current.goTo(index)
    else {
      setTimeout(() => {
        const currentSlideElement = document.querySelector(`.slideshow [data-index="${index}"]`)

        if(currentSlideElement) {
          window.scrollTo({
            top: currentSlideElement.offsetHeight > document.documentElement.clientHeight - 38
              ? currentSlideElement.offsetTop
              : currentSlideElement.offsetTop + currentSlideElement.offsetHeight / 2 - (document.documentElement.clientHeight - 38) / 2,
            behavior: 'instant'
          })
        }
      }, 0)
    }
  }, [mobile, index])

  // Attaches click event listener
  useEventListener('click', (e) => {
    if(clientWidth.current <= 1000) return
    if(info || e.target.closest('a')) return
    const method = e.clientX < clientWidth.current / 2
      ? 'prev' : 'next'
    slider.current[method]()
  }, { passive: true })

  // Attaches keyup event listener
  useEventListener('keyup', (e) => {
    if(clientWidth.current <= 1000) return
    switch(e.key) {
      case 'ArrowRight':
      case 'ArrowDown':
      case ' ':
        slider.current.next()
        break
      case 'ArrowLeft':
      case 'ArrowUp':
        slider.current.prev()
        break
      default:
        break
    }
  }, { passive: true })

  // Attaches mousemove event listener
  useEventListener('mousemove', (e) => {
    if(!cursor.current) return

    if(e.target.closest('a')) document.body.classList.add('cursor-hidden')
    else document.body.classList.remove('cursor-hidden')

    if(e.clientX > clientWidth.current / 2) {
      cursor.current.classList.remove('prev')
      cursor.current.classList.add('next')
    } else {
      cursor.current.classList.remove('next')
      cursor.current.classList.add('prev')
    }
    // TODO: Cross-browserify translations
    if(info) setVendorStyle(cursor.current, 'transform', `translate(${e.clientX}px, calc(-100vh + 150px + ${e.clientY}px))`)
    else setVendorStyle(cursor.current, 'transform', `translate(${e.clientX}px, ${e.clientY}px)`)
  }, { passive: true })

  // Attaches scroll event listener
  useEventListener('scroll', throttle(50, (e) => {
    if(document.documentElement.clientWidth > 1000) return
    const scrollTop = document.scrollingElement.scrollTop,
          // window.innerHeight reflects change in iOS Safari tap bar
          // wheras document.documentElement.clientHeight does not
          // Subtract 38 to accomodate mobile footer
          clientHeight = window.innerHeight - 38,
          slides = Array.from(document.querySelectorAll('.slick-slide:not(.slick-cloned)')),
          slidesCenterOffset = slides.map(s => Math.min(
            Math.abs(s.offsetTop - scrollTop - clientHeight / 2),
            Math.abs(s.offsetTop + s.offsetHeight - scrollTop - clientHeight / 2)
          )),
          slidesCenterOffsetMin = Math.min(...slidesCenterOffset),
          slideNearestCenterIndex = slidesCenterOffset.indexOf(slidesCenterOffsetMin)

    const info = document.querySelector('.info'),
          infoTop = info.offsetTop,
          scrollCenter = scrollTop + clientHeight / 2,
          scrollBottom = scrollTop + clientHeight
    if(scrollTop >= infoTop) document.body.classList.add('scroll-top')
    else document.body.classList.remove('scroll-top')
    if(scrollCenter > infoTop) document.body.classList.add('scroll-center')
    else document.body.classList.remove('scroll-center')
    if(scrollBottom > infoTop) document.body.classList.add('scroll-bottom')
    else document.body.classList.remove('scroll-bottom')

    if(slideNearestCenterIndex !== index) {
      setIndex(slideNearestCenterIndex)
    }
  }), { passive: true })

  // Keeps cursor ref
  const cursor = useRef(null)

  // Keeps slick settings
  const [slickSettings] = useState({
    arrows: false,
    dots: false,
    swipe: false,
    beforeChange: (oldIndex, newIndex) => setIndex(newIndex)
  })

  const [format, setFormat] = useState(undefined)
  const [client, setClient] = useState(undefined)
  const [caption, setCaption] = useState(undefined)

  // When slides or index change
  // * update format
  // * update client
  // * update caption
  useEffect(() => {
    if(typeof slideshow.slides === 'undefined') return

    const slide = slideshow.slides[index] && slideshow.slides[index].slice.primary
    const format = slideshow.slides[index] && slideshow.slides[index].slice.slice_type
    setFormat(format)

    const client = slideshow.type === 'client'
      ? slideshow.slideshow.title.text
      : slide && slide.client && slide.client.document[0] && slide.client.document[0].data.name.html
    setClient(client)

    const caption = slide && slide.caption && slide.caption.html
    setCaption(caption)
  }, [slideshow.slides, index])

  return (
    <>
      <div id="cursor" ref={cursor}>
        {slideshow.hasCredentials && (
          <style type="text/css">{`
            #cursor::before { content: "${index < 1 ? slideshow.length : index }"; }
            #cursor::after { content: "${index + 2 > slideshow.length ? 1 : index + 2 }"; }
          `}</style>
        )}
      </div>
      {slideshow.hasCredentials ? (
        <div className="slideshow" data-format={format}>
          <h1 id="client" dangerouslySetInnerHTML={{ __html: client }} />
          <div id="index">{index + 1}</div>
          <div id="caption" dangerouslySetInnerHTML={{ __html: caption }} />
          <Slider ref={sliderElement} {...slickSettings}>
            {slideshow.slides.map(object => object.slide)}
          </Slider>
        </div>
      ) : (
        <div className="slideshow password">
          <h1>{typeof slideshow.passwordInput === 'undefined'
            ? 'The requested content requires a password.'
            : 'Oops, that\'s not quite right.'
          }</h1>
          <form onSubmit={(e) => {
            e.preventDefault()
            const passwordElement = e.target.querySelector('input[type="password"]')
            slideshow.setPasswordInput(passwordElement.value)
          }}>
            <input type="password" placeholder="Enter password" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      )}
      {location.pathname === '/' ? (
        <Link className="index-link" to={'/'}>
          <span className="desktop">Now viewing 'Selected Work'</span>
          <span className="mobile">Home</span>
        </Link>
      ) : slideshow.hasCredentials ? (
        <Link className="index-link" to={'/'}>
          <span className="desktop">Viewing only '{slideshow.slideshow.title.text}' / Return to 'Selected Work'</span>
          <span className="mobile">Home</span>
        </Link>
      ) : (
        <Link className="index-link" to={'/'}>
          <span>Return to 'Selected Work'</span>
        </Link>
      )}
    </>
  )
}

export const slideshowVideoSliceQuery = graphql`
  fragment PrismicSlideshowBodyVideo on PrismicSlideshowBodyVideo {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      embed {
        type
        version
        provider_name
        provider_url
        embed_url
        uri
        video_id
        author_name
        author_url
        account_type
        is_plus
        width
        height
        duration
        description
        thumbnail_url
        thumbnail_width
        thumbnail_height
        thumbnail_url_with_play_button
        upload_date
        html
      }
      client {
        uid
        document {
          data {
            name {
              html
              text
            }
          }
        }
      }
      scale
      anchor_y
      anchor_x
    }
  }
`

export const clientVideoSliceQuery = graphql`
  fragment PrismicClientBodyVideo on PrismicClientBodyVideo {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      embed {
        type
        version
        provider_name
        provider_url
        embed_url
        uri
        video_id
        author_name
        author_url
        account_type
        is_plus
        width
        height
        duration
        description
        thumbnail_url
        thumbnail_width
        thumbnail_height
        thumbnail_url_with_play_button
        upload_date
        html
      }
      scale
      anchor_y
      anchor_x
    }
  }
`

export const slideshowTextSliceQuery = graphql`
  fragment PrismicSlideshowBodyText on PrismicSlideshowBodyText {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      text {
        html
      }
      client {
        uid
        document {
          data {
            name {
              html
              text
            }
          }
        }
      }
    }
  }
`

export const clientTextSliceQuery = graphql`
  fragment PrismicClientBodyText on PrismicClientBodyText {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      text {
        html
      }
    }
  }
`

export const slideshowImageSliceQuery = graphql`
  fragment PrismicSlideshowBodyImage on PrismicSlideshowBodyImage {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      scale
      image {
        url
        localFile {
          childImageSharp {
            fluid(maxWidth: 2, base64Width: 1, srcSetBreakpoints: [2]) {
              base64
            }
          }
        }
        dimensions {
          width
          height
        }
        alt
        copyright
      }
      client {
        uid
        document {
          data {
            name {
              html
              text
            }
          }
        }
      }
      scale
      anchor_y
      anchor_x
    }
  }
`

export const clientImageSliceQuery = graphql`
  fragment PrismicClientBodyImage on PrismicClientBodyImage {
    id
    slice_type
    primary {
      caption {
        html
        text
      }
      scale
      image {
        url
        localFile {
          childImageSharp {
            fluid(maxWidth: 2, base64Width: 1, srcSetBreakpoints: [2]) {
              base64
            }
          }
        }
        dimensions {
          width
          height
        }
        alt
        copyright
      }
      scale
      anchor_y
      anchor_x
    }
  }
`

export const slideshowQuery = graphql`
  query SlideshowsBySlug($uid: String!) {
    prismicSlideshow(uid: { eq: $uid }) {
      type
      uid
  		data {
        title {
          text
        }
        password
        body {
          __typename
          ... on PrismicSlideshowBodyImage {
            ... PrismicSlideshowBodyImage
          }
          ... on PrismicSlideshowBodyText {
          	... PrismicSlideshowBodyText
          }
          ... on PrismicSlideshowBodyVideo {
            ... PrismicSlideshowBodyVideo
          }
        }
      }
    }
    generatedSlideshow(uid: { eq: $uid }) {
      type
      uid
  		data {
        title {
          text
        }
        body {
          __typename
          ... on PrismicSlideshowBodyImage {
            ... PrismicSlideshowBodyImage
          }
          ... on PrismicSlideshowBodyText {
            ... PrismicSlideshowBodyText
          }
          ... on PrismicSlideshowBodyVideo {
            ... PrismicSlideshowBodyVideo
          }
          ... on PrismicClientBodyImage {
            ... PrismicClientBodyImage
          }
          ... on PrismicClientBodyText {
            ... PrismicClientBodyText
          }
          ... on PrismicClientBodyVideo {
            ... PrismicClientBodyVideo
          }
        }
      }
    }
  }
`
