import StudioProRegularEot from './StudioPro-Regular.eot'
import StudioProRegularWoff from './StudioPro-Regular.woff'
import StudioProRegularWoff2 from './StudioPro-Regular.woff2'

export default {
  StudioPro: {
    400: {
      normal: {
        eot: StudioProRegularEot,
        woff: StudioProRegularWoff,
        woff2: StudioProRegularWoff2
      }
    }
  }
}
